import home from '@/api/home';
var state = {
  groupDictCodes: {}
};
var mutations = {
  //获取字典
  updateGroupDictCodes: function updateGroupDictCodes(state, groupDictCodes) {
    state.groupDictCodes = groupDictCodes;
  }
};
var actions = {
  loadGroupDictCodes: function loadGroupDictCodes(_ref) {
    var commit = _ref.commit;
    home.getGroupDictCodes().then(function (data) {
      console.log('xxxx', data);
      commit('updateGroupDictCodes', {
        groupDictCodes: data
      });
    }, function (error) {
      // fail
      console.log(error);
    });
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions
};