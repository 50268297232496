var getters = {
  groupDictCodes: function groupDictCodes(state) {
    return state.home.groupDictCodes;
  },
  userName: function userName(state) {
    return state.app.userName;
  },
  fullPath: function fullPath(state) {
    return state.app.fullPath;
  },
  activeNames: function activeNames(state) {
    return state.app.activeNames;
  },
  isGet: function isGet(state) {
    return state.app.isGet;
  },
  scrollTo: function scrollTo(state) {
    return state.app.scrollTo;
  },
  domeScale: function domeScale(state) {
    return state.app.domeScale;
  }
};
export default getters;