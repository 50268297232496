import Vue from 'vue';
import Router from 'vue-router';
import { constantRouterMap } from './router.config.js';
var originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};

Vue.use(Router);

var createRouter = function createRouter() {
  return new Router({
    mode: 'history',
    // 如果你是 history模式 需要配置vue.config.js publicPath
    base: process.env.BASE_URL,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRouterMap
  });
};

var router = createRouter();
router.beforeEach(function (to, from, next) {
  // let status = JSON.parse(localStorage.getItem('userInfo')).status
  // console.log('status',status)
  if (to.path === '/') return next(); // let token = localStorage.getItem('token')
  // if (!token) return next ({    path: '*',name: 'test-login',})
  // console.log(subjectId)
  // if (!subjectId) return next('/404')

  next();
}); // router.beforeEach(({ meta, path, query }, from, next) => {
//   let info = localStorage.getItem('vuex') ? JSON.parse(localStorage.getItem('vuex')).home : null
//   let tokenList = ['/contract', '/contractType', '/electronicContract', '/succeed', '/paperContractType', '/paperContract']
//   console.log('info',info);
//   if (!info) {
//     // 没有说明是第一次进入
//     next()
//   } else if (tokenList.includes(path) && info.token) {
//     // 有token并且跳转到相应页面才可以跳转
//     next()
//   } else if (tokenList.includes(path) && !info.token) {
//     // 重置到login中
//     next('/login')
//   } else {
//     next()
//   }
// })
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;