// 本地环境配置
module.exports = {
  title: 'vue-h5-template',
  baseUrl: 'https://webapp-test.gzlle.com/merchants-register-wap/',
  // 项目地址
  baseApi: 'https://api-test.gzlle.com/',
  // 本地api请求地址,注意：如果你使用了代理，请设置成'/'
  APPID: 'xxx',
  APPSECRET: 'xxx',
  $cdn: 'https://cdn.zleyun.com',
  // 静态资源cos cdn链接
  $cdn1: 'https://cdn1.zleyun.com',
  $cdn2: 'https://cdn2.zleyun.com',
  $cdn3: 'https://cdn3.zleyun.com',
  $cdn4: 'https://cdn4.zleyun.com'
};