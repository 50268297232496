var state = {
  userName: '',
  fullPath: '',
  isGet: false,
  activeNames: ['1'],
  scrollTo: 0,
  domeScale: 0
};
var mutations = {
  SET_USER_NAME: function SET_USER_NAME(state, name) {
    state.userName = name;
  },
  setFullPath: function setFullPath(state, path) {
    state.fullPath = path;
  },
  setisGet: function setisGet(state, data) {
    state.isGet = data;
  },
  setScrollTo: function setScrollTo(state, data) {
    if (data === 0) return;
    state.scrollTo = data;
  },
  setActiveNames: function setActiveNames(state, data) {
    state.activeNames = data;
  },
  setdomeScale: function setdomeScale(state, data) {
    state.domeScale = data;
  }
};
var actions = {
  // 设置name
  setUserName: function setUserName(_ref, name) {
    var commit = _ref.commit;
    commit('SET_USER_NAME', name);
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions,
  namespaced: true
};