import _toConsumableArray from "/www/code/merchants-register-wap/node_modules/_@babel_runtime@7.14.0@@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import Vue from 'vue';
Vue.directive('setcolor', {
  bind: function bind(el, binding) {// el为绑定的元素，binding为绑定给指令的对象
  },
  componentUpdated: function componentUpdated(el, binding) {
    if (_toConsumableArray(el.classList).includes('van-field--error')) {
      var arr = el.lastChild;
      arr.style.display = 'none';
      arr.dataset.errstring = true;
    } else {
      var _arr = el.lastChild;
      _arr.style.display = 'block';

      if (_arr.dataset.errstring) {
        _arr.style.color = '#FF8C47';
      }
    }
  }
});