import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import store from '@/store';
// 根据环境不同引入不同api地址
import { baseApi } from '@/config';
import NProgress from 'nprogress'; // 进度条

import router from '@/router';
NProgress.configure({
  showSpinner: false
}); // 进度条
// create an axios instance

var service = axios.create({
  baseURL: baseApi,
  // url = base api url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 60000 // request timeout

});
service.interceptors.request.use(function (config) {
  // 不传递默认开启loading
  if (!config.hideloading) {
    // loading
    _Toast.loading({
      forbidClick: true
    });
  }

  var token = JSON.parse(localStorage.getItem('token'));

  if (token) {
    config.headers['token'] = token;
  } // config.headers['token'] = '7e95de9731af125bb6bb553bd289f133'
  // config.headers['subjectId'] = localStorage.getItem('fullPath').split('?pid=')[1]


  config.headers['subjectId'] = localStorage.getItem('subjectId'); // config.headers['subjectId'] = 'NTY1MjI5MTU1NDUzNTAxNDQw'

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  // return Promise.reject(error)
}); // respone拦截器

service.interceptors.response.use(function (response) {
  _Toast.clear(); // const res = response.data
  // if (res.status && res.status !== 200) {
  //   // 登录超时,重新登录
  //   if (res.status === 401) {
  //     store.dispatch('FedLogOut').then(() => {
  //       location.reload()
  //     })
  //   }
  //   return Promise.reject(res || 'error')
  // } else {
  //   return Promise.resolve(res)
  // }


  return response;
}, function (error) {
  _Toast.clear();

  console.log('err' + error); // for debug

  return Promise.reject(error);
});
export default service;