// import qs from 'qs'
// axios
import request from '@/utils/request'; // home api

export default {
  //获取信息
  getInformation: function getInformation() {
    return request({
      url: '/merchants/company/register/get/corp/info',
      method: 'get'
    });
  },
  //发送验证码
  sendCode: function sendCode(data) {
    // 获取发票品目
    return request({
      url: '/merchants/company/register/send/login/code',
      method: 'post',
      data: data
    });
  },
  //登录
  codeLogin: function codeLogin(data) {
    // 获取发票品目
    return request({
      url: '/merchants/company/register/login',
      method: 'post',
      data: data
    });
  },
  //进件合同确认
  contractConfirm: function contractConfirm() {
    // let token = localStorage.getItem('token')
    return request({
      url: '/merchants/company/register/confirm',
      method: 'get'
    });
  },
  //提交
  contractSubmit: function contractSubmit(data) {
    return request({
      url: '/merchants/company/register/manual',
      method: 'post',
      data: data
    });
  },
  //获取图片
  getcontractImg: function getcontractImg(data) {
    return request({
      url: '/merchants/company/register/getCosFile',
      method: 'post',
      data: data
    });
  },
  //获取字典
  getGroupDictCodes: function getGroupDictCodes() {
    return request({
      url: '/merchants/company/register/dict/groupList',
      method: 'get'
    });
  },
  getIndustry: function getIndustry() {
    // 获取行业信息
    return request({
      url: '/company/register/industry/all'
    });
  },
  getInitInfo: function getInitInfo(data) {
    // 获取链接信息
    return request({
      url: '/merchants/company/register/get',
      method: 'get',
      data: data,
      hideloading: true
    });
  },
  getContentList: function getContentList(data) {
    // 获取发票品目
    return request({
      url: '/company/register/getContent/list',
      method: 'post',
      data: data
    });
  },
  getCode: function getCode(data) {
    return request({
      url: '/company/register/send/save/code',
      method: 'post',
      hideloading: true,
      data: data
    });
  },
  getLoginCode: function getLoginCode(data) {
    return request({
      url: '/merchants/company/register/send/login/code',
      method: 'post',
      hideloading: true,
      data: data
    });
  },
  login: function login(data) {
    return request({
      url: '/company/register/login',
      method: 'post',
      hideloading: true,
      data: data
    });
  },
  getSelectPage: function getSelectPage(data) {
    return request({
      url: '/company/register/businessType/selectPage',
      method: 'post',
      hideloading: true,
      data: data
    });
  },
  getImg: function getImg(keys) {
    return request({
      url: "/company/register/get/url?key=".concat(keys),
      method: 'post',
      hideloading: true
    });
  },
  save: function save(data) {
    return request({
      url: '/company/register/save',
      method: 'post',
      hideloading: true,
      data: data
    });
  },
  submit: function submit(data) {
    return request({
      url: '/company/register/submit',
      method: 'post',
      hideloading: true,
      data: data
    });
  },
  getBase64ByMoulage: function getBase64ByMoulage(id) {
    return request({
      url: '/company/register/contract/getBase64ByMoulageId?moulageId=' + id,
      method: 'get',
      hideloading: true
    });
  },
  getExpress: function getExpress() {
    return request({
      url: '/company/register/search/system/express',
      method: 'post',
      hideloading: true
    });
  },
  sendEmail: function sendEmail(data, flag) {
    var url = flag ? '/company/register/project/application/form/sendEmail/' + data : '/company/register/electronic/seal/sendEmail/' + data;
    return request({
      url: url,
      method: 'get',
      hideloading: true
    });
  },
  getRegionList: function getRegionList() {
    var url = '/company/register/region/provinceAndCityList';
    return request({
      url: url,
      method: 'get',
      hideloading: true
    });
  },
  getCorpInfo: function getCorpInfo(data) {
    return request({
      url: '/company/register/get/corp/info',
      method: 'get',
      data: data,
      hideloading: true
    });
  },
  createMoulage: function createMoulage() {
    return request({
      url: '/company/register/create/moulage',
      method: 'post',
      hideloading: true
    });
  },
  downUrl: function downUrl(contractId) {
    return request({
      url: "/company/register/get/contract/url/".concat(contractId),
      method: 'get',
      hideloading: true
    });
  },
  getContract: function getContract(contractId) {
    return request({
      url: "/company/register/get/contract/".concat(contractId),
      method: 'get',
      hideloading: true
    });
  },
  contractEmail: function contractEmail(email) {
    return request({
      url: "/company/register/contract/sendEmail/".concat(email),
      method: 'get',
      hideloading: true
    });
  },
  setSign: function setSign(data) {
    return request({
      url: '/company/register/sign',
      method: 'post',
      hideloading: true,
      data: data
    });
  },
  downloadWord: function downloadWord() {
    return request({
      url: '/company/register/template/dowmload',
      method: 'get',
      responseType: 'arraybuffer',
      hideloading: true
    });
  },
  contractWordEmail: function contractWordEmail(email) {
    return request({
      url: "/company/register/contract/word/sendEmail/".concat(email),
      method: 'get',
      hideloading: true
    });
  }
};