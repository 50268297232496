import "vant/es/area/style";
import _Area from "vant/es/area";
import "vant/es/popover/style";
import _popover from "vant/es/popover";
import "/www/code/merchants-register-wap/node_modules/_core-js@3.12.1@core-js/modules/es.array.iterator.js";
import "/www/code/merchants-register-wap/node_modules/_core-js@3.12.1@core-js/modules/es.promise.js";
import "/www/code/merchants-register-wap/node_modules/_core-js@3.12.1@core-js/modules/es.object.assign.js";
import "/www/code/merchants-register-wap/node_modules/_core-js@3.12.1@core-js/modules/es.promise.finally.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Viewer from 'v-viewer';
import filters from './filters/filter';
import 'viewerjs/dist/viewer.css';
import '@/assets/icon/iconfont.css';
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.use(Viewer);
Vue.use(_popover);
import Clipboard from 'clipboard';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.prototype.Clipboard = Clipboard; // 设置 js中可以访问 $cdn

import { $cdn } from '@/config';
Vue.prototype.$cdn = $cdn;
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);
VueHammer.config.swipe = {
  threshold: 200
}; // 全局引入按需引入UI库 vant

import '@/plugins/vant'; // 引入全局样式

import '@/assets/css/index.scss'; // 移动端适配

import 'lib-flexible/flexible.js';
import vueEsign from 'vue-esign';
Vue.use(vueEsign); // filters

import './filters';
Vue.config.productionTip = false;
Vue.use(_Area);
import AlloyFinger from 'alloyfinger';
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue';
Vue.use(AlloyFingerPlugin, {
  AlloyFinger: AlloyFinger
});
import '@/utils/directives';
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});