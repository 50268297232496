import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/steps/style";
import _Steps from "vant/es/steps";
import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/divider/style";
import _Divider from "vant/es/divider";
import "vant/es/step/style";
import _Step from "vant/es/step";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/collapse/style";
import _Collapse from "vant/es/collapse";
import "vant/es/collapse-item/style";
import _CollapseItem from "vant/es/collapse-item";
import "vant/es/icon/style";
import _icon from "vant/es/icon";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/button/style";
import _Button from "vant/es/button";
// 按需全局引入 vant组件
import Vue from 'vue';
Vue.use(_Button);
Vue.use(_Cell);
Vue.use(_List);
Vue.use(_Tabbar).use(_TabbarItem);
Vue.use(_Form).use(_Field).use(_Uploader).use(_NavBar).use(_Collapse).use(_CollapseItem).use(_icon);
Vue.use(_Radio);
Vue.use(_RadioGroup);
Vue.use(_Step);
Vue.use(_Divider);
Vue.use(_Steps).use(_Search).use(_Checkbox).use(_Picker).use(_Popup);
Vue.use(_Dialog).use(_Notify).use(_ActionSheet).use(_Empty).use(_Overlay).use(_Loading);