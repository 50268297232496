import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

/**
 * 基础路由
 * @type { *[] }
 */
export var constantRouterMap = [{
  path: '/await',
  name: 'await',
  component: function component() {
    return import('@/views/home/await/index');
  }
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return import('@/views/error/404');
  }
}, {
  path: '*',
  name: 'test-login',
  component: function component() {
    return import('@/views/login/login');
  }
}, {
  path: '/test-info',
  name: 'test-info',
  component: function component() {
    return import('../views/new/test-info');
  }
}, {
  path: '/enterpriseInfo',
  name: 'enterpriseInfo',
  component: function component() {
    return import('../views/new/enterpriseInfo');
  }
}, {
  path: '/Electronic-signature',
  name: 'Electronic-signature',
  component: function component() {
    return import('../views/new/Electronic-signature');
  }
}, //contract-signing
{
  path: '/contract-signing',
  name: 'contract-signing',
  component: function component() {
    return import('../views/new/contract-signing');
  }
}, {
  path: '/online-contract',
  name: 'online-contract',
  component: function component() {
    return import('../views/new/online-contract');
  }
}, {
  path: '/form',
  name: 'form',
  component: function component() {
    return import('../views/new/form');
  }
}];