import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import home from './modules/home';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);
var persistedOptions = {
  paths: ['home']
};
var store = new Vuex.Store({
  plugins: [createPersistedState(persistedOptions)],
  modules: {
    app: app,
    home: home
  },
  getters: getters
});
export default store;