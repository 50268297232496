import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import store from '../store';
/**
 *格式化时间
 *yyyy-MM-dd hh:mm:ss
 */

export function formatDate(time, fmt) {
  if (time === undefined || '') {
    return;
  }

  var date = new Date(time);

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }

  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };

  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }

  return fmt;
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}
/*
 * 隐藏用户手机号中间四位
 */


export function setTime(str) {
  return str.split(' ')[0].replace('-', '年').replace('-', '月') + '日';
}
export function hidePhone(phone) {
  return phone === null || phone === void 0 ? void 0 : phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}
export function setSplit(data) {
  if (!data.includes('_')) return data;
  return data.split('_')[1];
}
export function hideCode(str) {
  var starNumber = Math.ceil(str.length / 3);
  var part = str.length - starNumber;
  var star = '';

  for (var i = 1; i <= starNumber; i++) {
    star += '*';
  }

  if (part % 2 !== 0) {
    star += '*';
    part--;
  }

  return str.substring(0, part / 2) + star + str.substring(part / 2 + star.length);
}

var codeToName = function codeToName(value, parent) {
  console.log('value', value);
  console.log('parent', parent);
  if (!parent || value === null || typeof value === 'undefined') return '';

  try {
    var dictCodes = store.getters.groupDictCodes.groupDictCodes;
    value = value.toString();
    var dict = dictCodes[parent].filter(function (item) {
      return item.code === value;
    });

    if (dict.length > 0) {
      return dict[0].name;
    } else {
      return '';
    }
  } catch (e) {// console.log(e)
  }
};

export default {
  codeToName: codeToName
};